import {Controller} from "@hotwired/stimulus"

let resizeDelayDuration = 600,
  resizeTimer = null,
  windowWidth = window.innerWidth

// Connects to data-controller="layouts--header"
export default class extends Controller {
  static targets = ['flashContainer', 'headerContainer', 'sidebarContainer', 'sidebar']

  connect() {
    this.loaded()
  }

  loaded() {
    this.fixed()
  }

  resizing() {
    this.fixed()
  }

  fixed() {
    const
      flashHeight = this.hasFlashContainerTarget ? this.flashContainerTarget.clientHeight : 0,
      headerHeight = this.headerContainerTarget.clientHeight

    this.element.classList.add('fixed')
    this.element.style.marginTop = `${flashHeight + headerHeight}px`

    if (!this.hasSidebarContainerTarget) return

    this.sidebarContainerTarget.style.marginTop = `${flashHeight + headerHeight}px`
  }
}
