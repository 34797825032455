import {Controller} from "@hotwired/stimulus"
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';

// Connects to data-controller="my-tasks--filters"
export default class extends Controller {
  static targets = ['container', 'btn']

  connect() {
    this.events()
  }

  toggle() {
    const that = this

    if (this.containerTarget.classList.contains('active')) {
      that.containerTarget.classList.remove('fade')

      setTimeout(function() {
        that.containerTarget.classList.remove('active')
        that.btnTarget.classList.remove('active')
      }, 100);
    } else {
      that.containerTarget.classList.add('active')
      that.btnTarget.classList.add('active')

      setTimeout(function() {
        that.containerTarget.classList.add('fade')
      }, 20);
    }
  }

  close() {
    const that = this

    that.containerTarget.classList.remove('fade')

    setTimeout(function() {
      that.containerTarget.classList.remove('active')
      that.btnTarget.classList.remove('active')
    }, 100);
  }

  clickOutsideType() {
    const that = this

    document.addEventListener('click', function(event) {
      const btnTypeAround = that.btnTarget.contains(event.target),
        containerTypeAround = that.containerTarget.contains(event.target),
        btnTypeVisible = window.getComputedStyle(that.btnTarget).visibility !== 'hidden',
        containerTypeVisible = window.getComputedStyle(that.containerTarget).visibility !== 'hidden',
        leftClicked = (event.which === 1)

      !btnTypeAround && !containerTypeAround && btnTypeVisible && containerTypeVisible && leftClicked && that.close()
    })
  }

  events() {
    this.clickOutsideType()
  }
}
