import {Controller} from '@hotwired/stimulus'
import {tns as TinySlider} from 'tiny-slider'
import LazyLoad from 'vanilla-lazyload';

let resize = {
    delayDuration: 300,
    timer: null
  },
  windowWidth = window.innerWidth

// Connects to data-controller="home--banner-sliders"
export default class extends Controller {
  static targets = ['containerLoading', 'containerSlider', 'containerInnerSlider']

  connect() {
    this.configSliders()
  }

  resizing() {
    this.resizeSlider()
  }

  lazyLoaded() {
    new LazyLoad({
      elements_selector: '.lazy',
      callback_loaded: function(element) {
        element.classList.remove('lazy')
        element.removeAttribute('data-srcset')

        if (!element.closest('.lazy-loading')) return

        element.closest('.lazy-loading').removeAttribute('data-srcset')
        element.closest('.lazy-loading').classList.remove('lazy-loading')
      }
    })
  }

  configSliders() {
    this.containerInnerSliderTargets.forEach((element) => {
      this.configSlider(element)
    })
  }

  configSlider(element) {
    const that = this,
      slider = TinySlider({
        container: element,
        onInit: that.initializeSlider(),
        items: 1,
        swipeAngle: false,
        speed: 600,
        autoplay: true,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        navPosition: 'bottom',
        controls: false,
        mouseDrag: true,
        preventActionWhenRunning: true
      })

    slider.events.on('touchStart', function() {
      slider.pause()
    })

    slider.events.on('dragMove', function() {
      slider.pause()
    })

    slider.events.on('dragEnd', function() {
      slider.pause()
    })

    slider.events.on('indexChanged', function() {
      element.querySelectorAll('.lazy') && that.lazyLoaded()
    })

    slider.play()
  }

  initializeSlider() {
    this.containerLoadingTargets.forEach((element) => {
      element.classList.remove('loading')
    })
  }

  blurSlider(state) {
    this.containerSliderTargets.forEach((element) => {
      element.classList.toggle('resizing', state)
    })
  }

  resizeSlider() {
    if (windowWidth === window.innerWidth) return

    const that = this

    that.blurSlider(true)
    clearTimeout(resize.timer)

    resize.timer = setTimeout(() => {
      that.blurSlider(false)
    }, resize.delayDuration)

    windowWidth = window.innerWidth
  }
}
