import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="talents--memo-mobile"
export default class extends Controller {
  static targets = ['memoBtn', 'memoContainer', 'memoContent']

  connect() {
    this.events()
  }

  toggle() {
    this.memoContainerTarget.classList.toggle('active')
  }

  close() {
    this.memoContainerTarget.classList.remove('active')
  }

  clickOutsideType() {
    const that = this

    document.addEventListener('click', function(event) {
      const btnTypeAround = that.memoBtnTarget.contains(event.target),
        containerTypeAround = that.memoContentTarget.contains(event.target),
        btnTypeVisible = window.getComputedStyle(that.memoBtnTarget).visibility !== 'hidden',
        containerTypeVisible = window.getComputedStyle(that.memoContentTarget).visibility !== 'hidden',
        leftClicked = (event.which === 1)

      !btnTypeAround && !containerTypeAround && btnTypeVisible && containerTypeVisible && leftClicked && that.close()
    })
  }

  events() {
    this.clickOutsideType()
  }
}
